import React, { useState } from 'react'
import './Des.css';

function Name(){
    return(
        <div className='description'>
            <h4><span className='label'>Full Name:</span> <span className='name'>Mekala Ganesh</span></h4>
            <h4><span className='label'>Age:</span>  <span className='name'>20</span></h4>
            <h4><span className='label'>Gender:</span> <span className='name'>Male</span></h4>
            <h4><span className='label'>Status:</span> <span className='name'>Single</span></h4>
        </div>
    )
}

function Des() {
    const [ganesh, setGanesh] = useState(false);
    
    console.log(ganesh);
    function clicking(){
        if(ganesh === false){
            setGanesh((ganesh)=>!ganesh)
            console.log(ganesh);
        }
        else if(ganesh === true){
            setGanesh((ganesh)=>!ganesh)
        }       
        
    }

    return (
        <div>
            <div>
                <h3 className='des'>Description
                <p className='intro'>
                    Hello! My Name is Ganesh a Tech enthusiastic and Love towards networking which allowed me compeletd CCNA Training from David Bomabal looking for Entry-level Techincal Job 
                    I hope this intro made myself clear about what i am intrested about.
                </p>
                </h3>
                <button className='btn' onClick={clicking}>Click Here</button>
                {ganesh && <Name />}
            
            </div>
        </div>
  )
}

export default Des;