import React from 'react'
import './App.css';
import './Des';
import Des from './Des';

function Home() {
  return (
    <div>
        <div className='body1'>
          <nav className='bodynav'>
            <h2>Profile</h2>
            <div>
              <img alt='myimage' className='ganeshimg' src='https://res.cloudinary.com/dk2unnk2o/image/upload/v1719497573/bj4zzs7lgsr1qq49okbr.jpg' />
            </div>
            <Des />
          
          </nav>
        </div>
    </div>
  )
}

export default Home