import React from 'react'
import './social.css';
function Social() {
    return (
        <div className='ganesh'>
            <h2>SocialPlatforms</h2>
            <div className='social'>
                
                <img alt='image1' className='x' src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/X_logo.jpg/1200px-X_logo.jpg' /><br></br>
                <a className='link1'   href='https://x.com/ganeshy12610627/'>X.com</a>
                <img alt='image1' className='insta' src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/240px-Instagram_icon.png' /><br></br>
                <a className='link2'   href='https://instagram.com/gana_yadav_/'>Instagram</a>
                <img alt='image1' className='far' src='https://pbs.twimg.com/media/GKPo6jTXwAAlde-.jpg'/> <br></br>
                <a className='link3'   href='https://warpcast.com/mekalaganesh'>Wrapcaster</a>
                    
                
                
            </div>
            
            
        </div>

        
    ) 
}

export default Social;