import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Social from './Social';
import Roadmap from './Roadmap';
import Layout from './Layout';
import Home from './Home';
import Hobbies from './Hobbies';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Layout />} >
            <Route index element={<Home />}></Route>
            <Route path='/socialmedia' element={<Social />}></Route>
            <Route path='/roadmap' element={<Roadmap />}></Route>
            <Route path='/hobbies' element={<Hobbies />}></Route>
          </Route>
        </Routes>
          
        
      </div>
    </Router>
  );
}

export default App;
