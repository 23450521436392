import React from 'react'
import {Outlet,Link} from 'react-router-dom';
import './App.css';
function Layout() {
  return (
    <div>
        <nav className='nav'>
          <li>
            <Link to='/'>Profile</Link>
          </li>
          <li>
            <Link to='/socialmedia'>Social Profiles</Link>
          </li>
          <li>
            <Link to='/hobbies'>Hobbies</Link>
          </li>
          <li>
            <Link to='/roadmap'>Roadmap</Link>
          </li>
          
          
        </nav>

        <Outlet />
    </div>
  )
}

export default Layout