import React from 'react';
import './hobbies.css';

function Books(){
    return(
        <>
            
            <div className='books'>
            <h3>Reading books</h3>       
                <img alt='image1' src='https://m.media-amazon.com/images/I/511vJPN7p5L._SY445_SX342_.jpg' />
                <a className='link11' href='https://www.amazon.in/Subtle-Art-Not-Giving/dp/0062641549/ref=sr_1_3?crid=696TQ1DILHUJ&dib=eyJ2IjoiMSJ9.kXemRnGMpiMmrtNlKJUOmNhQeawKPf6XaWDOzqbM1YrA_hR4NdpUtmtmzVZ4DvWDVLSKi0xA_rV398N3lQPHJ89-xi48N7oEJvfngukpX4la0jzdnUrLpR0IhSmSI3nC2BZQFi1PTpAn-BwO7VyZh4wq_rBXLoD4ZxkwnALa2aSsNolI_g56f6Pn_WjLdm8N87R79JD2yJ4wk6_fvnD22tyRHExXlRHeHOXehXZthwg.Crj1SPlEAOi9ywwB7J8Ww9SN437JQQJfmjGteh9Vw1k&dib_tag=se&keywords=subtle+art+of+not+giving+a+fuxk&qid=1719376606&s=books&sprefix=subt%2Cstripbooks%2C192&sr=1-3'>Buy Now</a>
                <img alt='image1' src='https://m.media-amazon.com/images/I/51YP7fM361S._SX342_SY445_.jpg' />
                <a className='link12' href='https://www.amazon.in/gp/product/8175992956/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1'>Buy Now</a>
                <img alt='image1' src='https://m.media-amazon.com/images/I/712mbINbEML._SY522_.jpg' />
                <a className='link13' href='https://www.amazon.in/gp/product/8175994053/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1'>Buy Now</a>
                <img alt='image1' src='https://m.media-amazon.com/images/I/41Z2jA9LIDL._SY445_SX342_.jpg' />
                <a className='link14' href='https://www.amazon.in/gp/product/8172344767/ref=ppx_yo_dt_b_search_asin_image?ie=UTF8&psc=1'>Buy Now</a>
                <img alt='image1' src='https://m.media-amazon.com/images/I/5127WRPgfrL._SY445_SX342_.jpg' />
                <a className='link15' href='https://www.amazon.in/gp/product/9384564192/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1'>Buy Now</a>
                <hr></hr>
            <h3>Movie Collections</h3>
                <img alt='image1' src='https://m.media-amazon.com/images/I/91g-WjXKe7L._AC_UF894,1000_QL80_.jpg' />
                <a href=' '>Intersellar</a>
                

                <img alt='image1' src='https://upload.wikimedia.org/wikipedia/en/4/4a/Oppenheimer_%28film%29.jpg' />
                <a href=' '>Oppenheimer</a>
                

                <img alt='image1' src='https://m.media-amazon.com/images/I/51a7hc58lDL._SX300_SY300_QL70_FMwebp_.jpg' />
                <a href=' ' >Inception</a>
               

                <hr></hr>
            <h3>Series</h3>
                <img alt='image1' src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Stranger_Things_logo.png/440px-Stranger_Things_logo.png' />
                <a href=' ' >Starger Things</a>
                
                <img alt='image1' src='https://m.media-amazon.com/images/M/MV5BMjFkNDNjYTktZjJiNi00Y2U3LWE2NDgtZDM3YjIzMWUzNGM2XkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_.jpg' />
                <a href=' ' >Silent sea</a>
                
                <img alt='image1' src='https://upload.wikimedia.org/wikipedia/en/1/1e/One_Piece_Live_Action_Logo.jpg' />
                <a href=' ' >One Peice</a>
                
                
            </div>
        </>
    )
}





function Hobbies() {
    return (
        <div>
            
            <div className='reading'>
                <h2>My Hobbies</h2>
                <Books />
            </div>
           
            
           
            
        </div>
    )
}

export default Hobbies